import { UtilityProgram } from '@/types/utilityProgram'
import { useMemo } from 'react'

export type DeviceIncentives = ReturnType<typeof getDeviceIncentives>
export const getDeviceIncentives = (utilityProgram: UtilityProgram) => ({
  vehicle: utilityProgram.view_config_json?.deviceIncentives?.vehicle ?? 0,
  thermostat: utilityProgram.view_config_json.deviceIncentives?.thermostat ?? 0,
  charger: utilityProgram.view_config_json.deviceIncentives?.charger ?? 0,
  behavioral: utilityProgram.view_config_json.deviceIncentives?.behavioral ?? 0,
  maxRewardDollars:
    utilityProgram.view_config_json.deviceIncentives?.maxIncentive ?? 0,
})

export const useCalculateTotalAwardedDollars = (
  selectedEligibleVehicleIds: number[],
  selectedEligibleThermostatIds: number[],
  selectedEligibleChargerIds: number[],
  deviceIncentives: DeviceIncentives,
) =>
  useMemo(
    () =>
      Math.min(
        selectedEligibleVehicleIds.length * deviceIncentives.vehicle +
          selectedEligibleThermostatIds.length * deviceIncentives.thermostat +
          selectedEligibleChargerIds.length * deviceIncentives.charger +
          deviceIncentives.behavioral,
        deviceIncentives.maxRewardDollars,
      ),
    [
      selectedEligibleVehicleIds,
      selectedEligibleThermostatIds,
      selectedEligibleChargerIds,
    ],
  )
